<template>
  <div class="goods-detail">
    <div class="preview-wrap">
      <!-- 视频播放区域 -->
      <div class="video-player-wrap" :class="{ show: switchMedia == 'video' }" v-if="!!goodsInfo.video_url">
        <video-player
          v-if="!!goodsInfo.video_url"
          ref="videoPlayer"
          :playsinline="true"
          :options="playerOptions"
          @play="onPlayerPlay($event)"
          @pause="onPlayerPause($event)"
          @ended="onPlayerEnded($event)"
          @waiting="onPlayerWaiting($event)"
          @playing="onPlayerPlaying($event)"
          @loadeddata="onPlayerLoadeddata($event)"
          @timeupdate="onPlayerTimeupdate($event)"
          @canplay="onPlayerCanplay($event)"
          @canplaythrough="onPlayerCanplaythrough($event)"
          @statechanged="playerStateChanged($event)"
          @ready="playerReadied"
        ></video-player>

        <div class="media-mode" v-if="!!goodsInfo.video_url">
          <span :class="{ 'ns-bg-color': switchMedia == 'video' }" @click="switchMedia = 'video'">视频</span>
          <span :class="{ 'ns-bg-color': switchMedia == 'img' }" @click="switchMedia = 'img'">图片</span>
        </div>
      </div>
      <div class="magnifier-wrap">
        <pic-zoom ref="PicZoom" :url="$img(picZoomUrl || defaultGoodsImage)" :scale="2"></pic-zoom>
      </div>

      <div class="spec-items">
        <span class="left-btn iconfont iconarrow-left-copy" :class="{ move: moveThumbLeft }" @click="changeThumbImg('prev')"></span>
        <span class="right-btn iconfont iconarrow-right" :class="{ move: moveThumbRight }" @click="changeThumbImg('next')"></span>
        <ul :style="{ left: 30 + thumbPosition + 'px' }">
          <!-- 商品缩率图 -->
          <li v-for="(item, index) in skuImageList" :key="index" @mousemove="picZoomUrl = item" :class="{ selected: picZoomUrl == item }">
            <img :src="$img(item, { size: 'small' })" @error="imageErrorSpec(index)" />
          </li>
        </ul>
      </div>
    </div>

    <!-- 商品信息  -->
    <div class="basic-info-wrap" v-loading="loading">
      <h1>{{ skuDataSelectInfo.sku_name || goodsInfo.goods_name }}</h1>
      <p class="desc ns-text-color" v-if="goodsInfo.introduction">{{ goodsInfo.introduction }}</p>
      <div class="item-block">
        <div class="promotion-price">
          <dl class="item-line">
            <dt class="ns-text-color-gray">销售价</dt>
            <dd v-if="token">
              <em class="yuan ns-text-color">¥</em>
              <span class="price ns-text-color">{{ skuDataSelectInfo.price || goodsInfo.price }}</span>
            </dd>
            <dd v-else>
              <span class="price">登录后显示</span>
            </dd>
          </dl>
          <dl class="item-line" v-if="goodsInfo.member_price > 0">
            <dt class="ns-text-color-gray">会员价</dt>
            <dd>
              <em class="market-yuan">¥</em>
              <span class="member-price">{{ skuDataSelectInfo.member_price || goodsInfo.member_price }}</span>
            </dd>
          </dl>
          <dl class="item-line" v-if="goodsInfo.market_price > 0">
            <dt class="ns-text-color-gray">市场价</dt>
            <dd>
              <em class="market-yuan">¥</em>
              <span class="market-price">{{ skuDataSelectInfo.market_price || goodsInfo.market_price }}</span>
            </dd>
          </dl>
          <dl class="item-line" v-if="goodsInfo.profit_margin > 0">
            <dt class="ns-text-color-gray">利润率</dt>
            <dd>
              <span class="member-price">{{ skuDataSelectInfo.profit_margin || goodsInfo.profit_margin }}%</span>
            </dd>
          </dl>
          <dl class="item-line" v-if="goodsInfo.is_virtual == 0">
            <dt>运费</dt>
            <dd>
              <i class="i-activity-flag ns-text-color ns-border-color">
                {{ shippingFeeInfo.freight == 0 ? '快递免邮' : shippingFeeInfo.freight || '请选择地区及规格' }}
              </i>
            </dd>
          </dl>
        </div>
      </div>
      <!-- 地址选择区域 -->
      <dl v-if="goodsInfo.is_virtual == 0" class="item-line delivery">
        <dt>配送至</dt>
        <dd>
          <div class="region-selected ns-border-color-gray">
            <span>
              <template v-if="selectedAddress['level_1']">
                <template v-for="item in selectedAddress">
                  {{ item.name }}
                </template>
              </template>
              <template v-else>请选择配送地址</template>
            </span>
            <i class="el-icon-arrow-down"></i>
          </div>

          <div class="region-list ns-border-color-gray" :class="{ hide: hideRegion }">
            <ul class="nav-tabs">
              <li :class="{ active: currTabAddres == 'province' }" @click="currTabAddres = 'province'">
                <div>
                  <span>{{ selectedAddress['level_1'] ? selectedAddress['level_1'].name : '请选择省' }}</span>
                  <i class="el-icon-arrow-down"></i>
                </div>
              </li>
              <li :class="{ active: currTabAddres == 'city' }" @click="currTabAddres = 'city'">
                <div>
                  <span>{{ selectedAddress['level_2'] ? selectedAddress['level_2'].name : '请选择市' }}</span>
                  <i class="el-icon-arrow-down"></i>
                </div>
              </li>
              <li :class="{ active: currTabAddres == 'district' }" @click="currTabAddres = 'district'">
                <div>
                  <span>{{ selectedAddress['level_3'] ? selectedAddress['level_3'].name : '请选择区/县' }}</span>
                  <i class="el-icon-arrow-down"></i>
                </div>
              </li>
              <li :class="{ active: currTabAddres == 'street' }" @click="currTabAddres = 'street'">
                <div>
                  <span>{{ selectedAddress['level_4'] ? selectedAddress['level_4'].name : '请选择街道' }}</span>
                  <i class="el-icon-arrow-down"></i>
                </div>
              </li>
            </ul>
            <div class="tab-content">
              <div class="tab-pane" :class="{ active: currTabAddres == 'province' }">
                <ul class="province">
                  <li
                    v-for="(item, index) in provinceArr"
                    :key="index"
                    :class="{ selected: selectedAddress['level_' + item.level] && selectedAddress['level_' + item.level].id == item.id }"
                  >
                    <span @click="getAddress('city', item)">{{ item.name }}</span>
                  </li>
                </ul>
              </div>
              <div class="tab-pane" :class="{ active: currTabAddres == 'city' }">
                <ul class="city">
                  <li
                    v-for="(item, index) in cityArr"
                    :key="index"
                    :class="{ selected: selectedAddress['level_' + item.level] && selectedAddress['level_' + item.level].id == item.id }"
                  >
                    <span @click="getAddress('district', item)">{{ item.name }}</span>
                  </li>
                </ul>
              </div>
              <div class="tab-pane" :class="{ active: currTabAddres == 'district' }">
                <ul class="district">
                  <li
                    v-for="(item, index) in districtArr"
                    :key="index"
                    :class="{ selected: selectedAddress['level_' + item.level] && selectedAddress['level_' + item.level].id == item.id }"
                  >
                    <span @click="getAddress('street', item)">{{ item.name }}</span>
                  </li>
                </ul>
              </div>
              <div class="tab-pane" :class="{ active: currTabAddres == 'street' }">
                <ul class="street">
                  <li
                    v-for="(item, index) in streetArr"
                    :key="index"
                    :class="{ selected: selectedAddress['level_' + item.level] && selectedAddress['level_' + item.level].id == item.id }"
                  >
                    <span @click="getAddress('community', item)">{{ item.name }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </dd>
        <dd style="text-align: right; flex: 1">
          <el-button type="primary" plain size="mini" icon="el-icon-download" @click.stop="download(goodsSkuDetail.goods_id)">资源下载</el-button>
        </dd>
      </dl>
      <hr class="divider" />
      <dl v-show="goodsInfo.goods_id" class="item-line">
        <dt>商品ID</dt>
        <dd>
          <div class="goods-attribute">
            {{ goodsInfo.goods_id }}
          </div>
        </dd>
      </dl>
      <dl class="item-line">
        <dt>SKU ID</dt>
        <dd>
          <div class="goods-attribute">
            {{ skuDataSelectInfo.sku_id || '-' }}
          </div>
        </dd>
      </dl>
      <!-- 规格选择区域 -->
      <div class="sku-list" v-if="skuDataSelectList.length > 0">
        <dl class="item-line" v-for="(item, index) in skuDataSelectList" :key="index">
          <dt>{{ item.name }}</dt>
          <dd>
            <ul>
              <li v-for="sku in item.sku" :key="sku.value">
                <div
                  :class="{
                    'selected ns-border-color': skuDataSelect[index] === sku.value,
                    disabled: sku.disabled,
                  }"
                  @click="skuChange(index, sku)"
                >
                  <img v-if="sku.image" :src="$img(sku.image, { size: 'small' })" />
                  <span>{{ sku.value }}</span>
                  <i class="iconfont iconduigou1 ns-text-color"></i>
                </div>
              </li>
            </ul>
          </dd>
        </dl>
      </div>

      <div class="buy-number">
        <dl class="item-line">
          <dt>数量</dt>
          <dd>
            <div class="num-wrap">
              <el-input v-model="number" placeholder="0" @input="keyInput()"></el-input>
              <div class="operation">
                <span class="increase el-icon-caret-top" @click="changeNum('+')"></span>
                <span class="decrease el-icon-caret-bottom" @click="changeNum('-')"></span>
              </div>
            </div>
            <span v-show="skuDataSelectInfo.min_buy" class="inventory">起购：{{ skuDataSelectInfo.min_buy }}</span>
          </dd>
        </dl>
      </div>

      <dl class="item-line buy-btn">
        <dt></dt>
        <dd>
          <template v-if="skuDataSelectInfo.goods_state == 1 && skuDataSelectInfo.verify_state == 1 && shippingFeeInfo.stock > 0">
            <el-button type="primary" icon="el-icon-shopping-cart-2" v-if="goodsInfo.is_virtual == 0" @click="joinCart" style="background: #ff860f">
              加入采购单
            </el-button>
            <el-button v-if="goodsInfo.isSelection" type="primary" plain @click.stop="deleteGoodsselection(goodsInfo)">取消选品</el-button>
            <el-button v-else type="primary" @click="addGoodsselection(goodsInfo)">加入选品库</el-button>
          </template>
          <template v-else>
            <el-button type="info" plain disabled>{{ skuDataSelect.indexOf('') !== -1 ? '请选择规格' : '库存不足' }}</el-button>
          </template>
          <!-- <div href="javascript:;" class="go-phone">
            <span style="color: #666666; margin-right: 10px">手机购买</span>
            <img src="@/assets/images/goods/qrcode.png" />
            <div class="qrcode-wrap"><img :src="qrcode" alt="二维码图片" /></div>
          </div> -->
        </dd>
      </dl>
    </div>

    <div class="detail-wrap">
      <div class="goods-recommended">
        <goods-recommend />
      </div>

      <el-tabs class="goods-tab" v-model="tabName" type="card">
        <el-tab-pane label="商品详情" name="detail">
          <div v-html="goodsInfo.goods_content"></div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
  import PicZoom from 'vue-piczoom'
  import detail from './detail'
  import GoodsRecommend from '@/components/GoodsRecommend'
  export default {
    name: 'detail',
    components: {
      PicZoom,
      GoodsRecommend,
    },
    mixins: [detail],
  }
</script>
<style lang="scss">
  @import './detail.scss';
</style>
