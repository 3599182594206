<template>
  <div class="goods-recommend" v-loading="loading">
    <h4>商品精选</h4>
    <ul v-if="list.length">
      <li v-for="(item, index) in list" :key="index" @click="$router.pushToTab({ path: '/newsku-' + item.goods_id })">
        <div class="img-wrap"><img :src="$img(item['sku_image'], { size: 'mid' })" referrerPolicy="no-referrer" @error="imageError(index)" /></div>
        <div class="price">
          <span v-if="token">￥{{ item.discount_price }}</span>
          <span class="market_price">￥{{ item.market_price }}</span>
        </div>
        <div class="profit_margin">利润率：{{ item.profit_margin }}%</div>
        <p class="sku-name">{{ item.goods_name }}</p>
        <div class="info-wrap"></div>
      </li>
    </ul>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { goodsRecommend } from '../api/goods/goods'
  export default {
    name: 'goods_recommend',
    props: {
      page: {
        type: [Number, String],
        default: 1,
      },
      pageSize: {
        type: [Number, String],
        default: 5,
      },
    },
    data: () => {
      return {
        loading: true,
        list: [],
      }
    },
    created() {
      this.getGoodsRecommend()
    },
    computed: {
      ...mapGetters(['token', 'defaultGoodsImage']),
    },
    methods: {
      getGoodsRecommend() {
        goodsRecommend({
          page: this.page,
          page_size: this.pageSize,
        })
          .then(res => {
            if (res.code == 0) this.list = res.data.list
            this.loading = false
          })
          .catch(res => {
            this.loading = false
          })
      },
      imageError(index) {
        this.list[index].sku_image = this.defaultGoodsImage
      },
    },
  }
</script>

<style lang="scss" scoped>
  .goods-recommend {
    border: 1px solid #eeeeee;
    h4 {
      margin: 10px;
    }
    ul {
      li {
        padding: 10px;
        cursor: pointer;
        .price {
          color: $base-color;
          font-size: 16px;
          .market_price {
            font-size: 14px;
            color: #838383;
            text-decoration: line-through;
            margin-left: 10px;
          }
        }
        .profit_margin {
          color: #838383;
        }
        .sku-name {
          font-size: $ns-font-size-sm;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        &:hover {
          color: $base-color;
        }
      }
    }
  }
</style>
