import { goodsSkuDetailNew, getShippingFee, addGoodsbrowse, goodsQrcode, addGoodsselection, deleteGoodsselection } from '@/api/goods/goods'
import { mapGetters } from 'vuex'
import CountDown from 'vue2-countdown'
import { getArea } from '@/api/address'
export default {
  data: () => {
    return {
      skuId: 0,
      goods_id: 0,
      loading: true,
      picZoomUrl: '',
      thumbPosition: 0,
      // 是否可以移动
      moveThumbLeft: false,
      moveThumbRight: false,
      // 商品详情
      goodsInfo: {},
      skuData: [], // 规格列表
      skuDataSelectList: [], // 规格选择列表
      skuDataSelect: [], // 规格选择组合
      // 商品详情
      goodsSkuDetail: {
        video_url: '',
      },
      discountText: '距离结束仅剩',
      discountTimeMachine: {
        currentTime: 0,
        startTime: 0,
        endTime: 0,
      },
      qrcode: '',
      specDisabled: false,
      specBtnRepeat: false, //防止重复
      btnSwitch: false,

      // 店铺详情
      shopInfo: {},
      whetherCollection: 0,
      score: 0,

      couponList: [], //优惠券列表
      couponBtnRepeat: false, //获取优惠券防止重复提交

      manjian: {}, //满减活动列表

      //评价
      currentPage: 1,
      pageSize: 10,
      total: 0,
      goodsEvaluateList: [],
      //组合套餐
      bundling: [
        {
          bundling_goods: {
            bl_name: '',
            sku_image: '',
          },
        },
      ],
      service: null,
      number: 1,
      tabName: 'detail',
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0, 3.0], // 可选的播放速度
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: 'video/mp4', // 类型
            src: '', // url地址
          },
        ],
        poster: '', // 封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: true, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      },
      switchMedia: 'img',

      // 是否关注店铺
      hasFollow: false,

      // 省/市/区县/街道 列表
      provinceArr: {},
      cityArr: {},
      districtArr: {},
      streetArr: {},
      // 省/市/区县/街道 id
      provinceId: null,
      cityId: null,
      districtId: null,
      streetId: null,

      // 省市区县 // 当前选中的城市级别
      currTabAddres: 'province',
      hideRegion: false,
      selectedAddress: {},
      addressErrorText: null,
      // 运费及库存
      shippingFeeInfo: {
        freight: null,
        stock: 0,
      },
    }
  },
  components: {
    CountDown,
  },
  created() {
    this.goods_id = this.$route.params.pathMatch
    this.addressInit()
    this.goodsDetailInit()
  },
  onShow() {},
  computed: {
    ...mapGetters(['token', 'defaultHeadImage', 'defaultShopImage', 'defaultGoodsImage', 'addonIsExit', 'locationRegion']),
    // 当前能匹配的规格数据
    skuDataSelectInfo() {
      let json = {}
      for (let i = 0, len = this.skuData.length; i < len; i++) {
        const item = this.skuData[i]
        const attrList = item.attr.map(v => v.val)
        if (attrList.toString() === this.skuDataSelect.toString()) {
          json = item
          break
        }
      }
      return json
    },
    /** 规格可选组合列表 */
    skuAttrList() {
      let arr = []
      this.skuData.forEach(item => {
        arr.push(item.attr.map(v => v.val))
      })
      return arr
    },
    // 商品缩略图列表
    skuImageList() {
      if (this.skuDataSelectInfo.sku_images && this.skuDataSelectInfo.sku_images.length > 0) {
        this.picZoomUrl = this.skuDataSelectInfo.sku_images[0]
        return this.skuDataSelectInfo.sku_images
      } else {
        this.picZoomUrl = this.goodsInfo.goods_image
        return [this.goodsInfo.goods_image]
      }
    },
  },
  watch: {
    $route: function(curr) {
      this.goods_id = curr.params.pathMatch
      this.goodsDetailInit()
    },
    // 规格选择详情监听事件
    skuDataSelectInfo: {
      deep: true,
      immediate: true,
      handler() {
        this.getShippingFee()
      },
    },
    // 地址变化监听事件
    selectedAddress: {
      deep: true,
      handler() {
        this.getShippingFee()
      },
    },
    // 规格数据变化禁用规格 设置
    skuDataSelect: {
      deep: true,
      handler() {
        this.skuDiasbledSet()
      },
    },
  },
  methods: {
    // 商品详情初始化方法
    async goodsDetailInit() {
      await this.getGoodsInfo()
      this.modifyGoodsInfo()
      // this.getGoodsQrcode()
    },
    /** 查询商品详情信息 */
    async getGoodsInfo() {
      this.loading = true
      const res = await goodsSkuDetailNew({ goods_id: this.goods_id })
      if (!res.data) return this.$router.push('/')
      this.goodsInfo = res.data
      this.skuData = res.data.sku_data || []
      this.setSkuDataSelectList()
      //媒体
      // if (this.goodsInfo.video_url) {
      //   this.switchMedia = 'video'
      //   this.playerOptions.poster = img(this.goodsInfo.sku_images[0])
      //   this.playerOptions.sources[0].src = img(this.goodsInfo.video_url)
      // }
      this.loading = false
    },
    // 设置规格展示列表
    setSkuDataSelectList() {
      let arr = []
      let skuDataSelect = []
      this.skuData.forEach((item, index) => {
        item.attr.forEach((attr, attrIndex) => {
          if (index === 0) {
            arr[attrIndex] = { name: attr.name, sku: [{ value: attr.val, disabled: false }] }
            skuDataSelect[attrIndex] = attr.val
          } else {
            if (arr[attrIndex].sku.find(v => v.value === attr.val) === undefined) {
              arr[attrIndex].sku.push({ value: attr.val, disabled: false })
            }
          }
        })
      })
      this.skuDataSelectList = arr
      this.skuDataSelect = skuDataSelect
    },
    // 规格禁用设置
    skuDiasbledSet() {
      this.skuDataSelectList.forEach((item, index) => {
        item.sku.forEach((skuItem, skuIndex) => {
          // 当前选中的值等于 当前
          if (this.skuDataSelect[index] === skuItem.value) {
            skuItem.disabled = false
            return
          }
          if (this.isSkuExist(index, skuItem.value) === false) {
            skuItem.disabled = false
            return
          }
          skuItem.disabled = true
        })
      })
    },
    // 判断组合是否存在
    isSkuExist(index, name) {
      let select = [...this.skuDataSelect]
      select[index] = name
      if (select.indexOf('') !== -1) {
        return false
      }
      let bl = true
      for (let i = 0, len = this.skuAttrList.length; i < len; i++) {
        if (this.skuAttrList[i].toString() === select.toString()) {
          bl = false
          break
        }
      }
      return bl
    },
    // 规格点击事件
    skuChange(index, val) {
      if (val.disabled) return
      if (this.skuDataSelect[index] === val.value) {
        this.$set(this.skuDataSelect, index, '')
      } else {
        this.$set(this.skuDataSelect, index, val.value)
      }
    },

    /** 地址初始化 */
    async addressInit() {
      // 加载省份
      await this.getAddress('province', null, true)
      // 清除脏缓存
      if (this.locationRegion && this.locationRegion.level_1 && this.selectedAddress.level_1 === 110000) {
        this.locationRegion = {}
      }
      this.selectedAddress = this.locationRegion || {}
      // 省份默认赋值
      if (!this.selectedAddress.level_1) {
        this.$set(this.selectedAddress, 'level_1', this.provinceArr[0])
      }
      // 加载城市
      this.provinceId = this.selectedAddress.level_1.id
      await this.getAddress('city', null, true)
      // 城市默认赋值
      if (!this.selectedAddress.level_2) {
        this.$set(this.selectedAddress, 'level_2', this.cityArr[0])
        this.selectedAddress['level_2'] = this.cityArr[0]
      }
      // 加载区县
      this.cityId = this.selectedAddress.level_2.id
      await this.getAddress('district', null, true)
      // 区县默认赋值
      if (!this.selectedAddress.level_3) {
        this.$set(this.selectedAddress, 'level_3', this.districtArr[0])
      }
      // 加载街道
      this.districtId = this.selectedAddress.level_3.id
      await this.getAddress('street', null, true)
      // 街道默认赋值
      if (!this.selectedAddress.level_4) {
        this.$set(this.selectedAddress, 'level_4', this.streetArr[0])
      }
    },
    /** 查询运费及库存 */
    async getShippingFee() {
      const street = this.selectedAddress.level_4
      if (!street) {
        this.addressErrorText = '请选择完整地址'
        return
      }
      if (!this.skuDataSelectInfo.sku_id) {
        this.shippingFeeInfo = {}
        return
      }
      this.addressErrorText = null
      // 获取街道Id
      const available = street.available.split('-')
      const region_id = available.indexOf(String(this.goodsInfo.site_id)) === -1 ? street.pid : street.id
      const res = await getShippingFee({
        region_id: region_id,
        sku_id: this.skuDataSelectInfo.sku_id,
      })
      if (res.data) {
        this.shippingFeeInfo = res.data
      }
    },
    /** 缩略图翻页 */
    changeThumbImg(tag) {
      if (this.skuImageList.length < 4) return
      let page = this.skuImageList.length % 4 // 可见数量4个
      let position = 88
      if (page == 0) page = this.skuImageList.length - 4
      // 可见数量4个
      else if (page != 0 && page != 1 && page < 2) return

      if (tag == 'prev') {
        if (this.thumbPosition != 0 && Math.round(this.thumbPosition, 2) != position) {
          this.thumbPosition += position
          // this.moveThumbLeft = true;
        } else {
          // this.moveThumbLeft = false;
        }
      } else if (tag == 'next') {
        if (Math.round(this.thumbPosition, 2) != -Math.round(position * page, 2)) {
          this.thumbPosition -= position
        } else {
        }
      }
    },
    //更新商品信息
    modifyGoodsInfo() {
      //添加足迹
      addGoodsbrowse({ goods_id: this.goods_id })
    },
    // 商品二维码
    getGoodsQrcode() {
      goodsQrcode({
        sku_id: this.skuId,
      }).then(res => {
        let data = res.data
        if (data.path.h5.img) this.qrcode = img(data.path.h5.img)
      })
    },
    /** 购买数量加减功能 */
    changeNum(tag) {
      const stock = this.goodsSkuDetail.stock
      if (!stock) return
      const min = this.skuDataSelectInfo.min_buy || 1
      if (tag == '+') {
        // 加
        if (this.number < stock) {
          this.number++
        } else {
          return
        }
      } else if (tag == '-') {
        // 减
        if (this.number > min) {
          this.number -= 1
        } else {
          return
        }
      }
    },
    // 输入数量
    keyInput() {
      const stock = this.skuDataSelectInfo.stock
      const min_buy = this.skuDataSelectInfo.min_buy
      // 库存为0
      if (stock == 0) {
        this.number = 0
        return
      }
      // 防止空
      if (this.number == 0 || this.number == '') this.number = 1
      const re = /^\d+$/
      if (re.test(parseInt(this.number))) {
        if (this.number > stock) {
          this.number = stock
        }
        if (this.number < min_buy) {
          this.number = min_buy
        }
        this.number = parseInt(this.number)
      } else {
        this.number = 1
      }
    },
    // 播放回调
    onPlayerPlay(player) {},
    // 暂停回调
    onPlayerPause(player) {},
    // 视频播完回调
    onPlayerEnded(player) {},
    // DOM元素上的readyState更改导致播放停止
    onPlayerWaiting(player) {},
    // 已开始播放回调
    onPlayerPlaying(player) {},
    // 当播放器在当前播放位置下载数据时触发
    onPlayerLoadeddata(player) {},
    // 当前播放位置发生变化时触发。
    onPlayerTimeupdate(player) {},
    //媒体的readyState为HAVE_FUTURE_DATA或更高
    onPlayerCanplay(player) {},
    //媒体的readyState为HAVE_ENOUGH_DATA或更高。这意味着可以在不缓冲的情况下播放整个媒体文件。
    onPlayerCanplaythrough(player) {},
    //播放状态改变回调
    playerStateChanged(playerCurrentState) {},
    //将侦听器绑定到组件的就绪状态。与事件监听器的不同之处在于，如果ready事件已经发生，它将立即触发该函数。。
    playerReadied(player) {},
    // 加入采购单
    joinCart() {
      //纠正数量
      if (this.skuDataSelectInfo.stock == 0) {
        this.$message({
          message: '商品已售罄',
          type: 'warning',
        })
        return
      }

      if (this.number.length == 0 || this.number == 0) {
        this.$message({
          message: '购买数量不能为0',
          type: 'warning',
        })
        return
      }
      if (parseInt(this.number) < parseInt(this.skuDataSelectInfo.min_buy)) {
        this.$message({
          message: '商品' + this.skuDataSelectInfo.min_buy + '件起购',
          type: 'warning',
        })
        return
      }

      if (this.btnSwitch) return
      this.btnSwitch = true

      this.$store
        .dispatch('cart/add_to_cart', {
          site_id: this.goodsInfo.site_id,
          sku_id: this.goodsInfo.sku_id,
          num: this.number,
        })
        .then(res => {
          var data = res.data
          if (data > 0) {
            this.$message({
              message: '加入采购单成功',
              type: 'success',
            })
          }
          this.btnSwitch = false
        })
        .catch(err => {
          if (err.message == 'token不存在') {
            this.$router.pushToTab('/login')
          } else {
            this.$message.error(err.message)
          }
        })
    },
    // 加入选品库
    addGoodsselection(row) {
      addGoodsselection({ goods_id: row.goods_id }).then(res => {
        this.$message.success('加入选品库成功')
        row.isSelection = true
      })
    },
    //删除选品库商品
    deleteGoodsselection(row) {
      deleteGoodsselection({ goods_id: row.goods_id })
        .then(res => {
          if (res.code == 0) {
            this.$message({ message: '删除成功', type: 'success' })
            row.isSelection = false
          }
        })
        .catch(err => {
          this.$message.error(err.message)
        })
    },
    // 图片加载失败
    imageErrorSpec(index) {
      this.picZoomUrl = this.defaultGoodsImage
    },
    /**
     * 获取地址
     * @param {Object} type 地址类型
     * @param {Object} item
     * @param {Object} first 是否第一次
     */
    async getAddress(type, item, first, callback) {
      let pid = 0
      switch (type) {
        case 'province':
          //加载省
          pid = 0
          break
        case 'city':
          //加载市
          if (item) {
            this.provinceId = item.id
          }
          pid = this.provinceId
          this.cityArr = {}
          this.districtArr = {}
          this.streetArr = {}
          break
        case 'district':
          //加载区县
          if (item) this.cityId = item.id
          pid = this.cityId
          this.districtArr = {}
          this.streetArr = {}
          break
        case 'street':
          //加载街道
          if (item) this.districtId = item.id
          pid = this.districtId
          this.streetArr = {}
          break
      }
      if (item) {
        if (item.level <= 3) {
          let len = item.level
          for (let i = len; i <= 4; i++) {
            delete this.selectedAddress['level_' + i]
          }
        }
        this.$set(this.selectedAddress, 'level_' + item.level, item)
      }

      if (!first) this.$store.commit('app/SET_LOCATION_REGION', this.selectedAddress)
      this.$forceUpdate()
      if (type == 'community') {
        this.hideRegion = true
        setTimeout(() => {
          this.hideRegion = false
        }, 10)
        return
      }

      const { data } = await getArea({ pid: pid })
      if (data) {
        switch (type) {
          case 'province':
            //加载省
            this.provinceArr = data
            break
          case 'city':
            //加载市
            this.cityArr = data
            break
          case 'district':
            //加载区县
            this.districtArr = data
            break
          case 'street':
            //加载街道
            this.streetArr = data
            break
        }
        this.currTabAddres = type

        if (callback) callback()
      }
    },
  },
}
